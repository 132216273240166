import { ScreenText } from '@/lang/ScreenText';
import BaseModal from '@/popupcomponents/BaseModal.vue';
import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: {
    BaseModal
  },
})
export default class DeleteCollectionModal extends Vue {
  screenText = new ScreenText();

  @PropSync('isVisible', { type: Boolean, required: true })
  isVisibleSync!: boolean;

  cancel() {
    this.$emit('cancel');
  }

  confirm() {
    this.$emit('confirm');
  }
}
